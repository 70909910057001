exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-fes-event-js": () => import("./../../../src/templates/fes-event.js" /* webpackChunkName: "component---src-templates-fes-event-js" */),
  "component---src-templates-fes-events-js": () => import("./../../../src/templates/fes-events.js" /* webpackChunkName: "component---src-templates-fes-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-past-event-js": () => import("./../../../src/templates/past-event.js" /* webpackChunkName: "component---src-templates-past-event-js" */),
  "component---src-templates-past-events-js": () => import("./../../../src/templates/past-events.js" /* webpackChunkName: "component---src-templates-past-events-js" */),
  "component---src-templates-sochi-js": () => import("./../../../src/templates/sochi.js" /* webpackChunkName: "component---src-templates-sochi-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

